import Vue from 'vue'

import { Chart, registerables } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'

import Sticky from 'vue-sticky-directive'
import VueHead from 'vue-head' // use https://github.com/unjs/unhead instead with vue3
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import i18n from '@/plugins/i18n'

import App from '@/App'
import router from '@/router'
import store from '@/store'
import VueLuxon from 'vue-luxon'

Vue.config.productionTip = false
Vue.config.devtools = true

i18n.locale = store.state.locale.current

// Overwrite default chart styling
Chart.register(...registerables, annotationPlugin, {
  id: 'canvasColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = options.color || 'transparent'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  },
})
Chart.defaults.font = {
  ...Chart.defaults.font,
  family: 'Inter',
  size: 14,
}
Chart.defaults.plugins.tooltip = {
  ...Chart.defaults.plugins.tooltip,
  titleSpacing: 10,
  bodySpacing: 10,
  titleFontSize: 14,
  titleMarginBottom: 10,
  padding: 12,
  xPadding: 12,
  yPadding: 12,
  boxPadding: 5,
  cornerRadius: 5,
}

Vue.component('v-select', vSelect)
Vue.use(VueLuxon)
Vue.use(Sticky)
Vue.use(VueHead, {
  separator: '-',
})

Vue.directive('matchText', (el, binding) => {
  const text = el.textContent
  const searchText = binding.value
  if (searchText) {
    const match = text.toLowerCase().match(searchText)
    if (match) {
      el.innerHTML = `<em class="match">${text}</em>`
      return
    }
  }
  el.innerHTML = text
})

Vue.mixin({
  methods: {
    /**
     * Formats a number, returning a dash if the input is falsy or NaN.
     * Example: formatNumber(123.4324, 2) returns "123.43"
     * Example: formatNumber(3.522, 1) returns "3.5"
     * Example: formatNumber(1234.5678, -2) returns "1200"
     * @param {number} x - The number to format.
     * @param {number} precision - The number of digits after the point (negative numbers round to 10s, 100s, 1000s, ...).
     * @returns {string} Formatted number or dash.
     */
    formatNumber(x, precision, thousandSeparator = true) {
      if (x == null) return '-'

      const num = Number(x)
      if (isNaN(num)) return '-'

      const fixed =
        precision != null ? (Math.round(num * 10 ** precision) / 10 ** precision).toString() : num.toString()
      const [intPart, fracPart] = fixed.split('.')

      const resultInt = thousandSeparator ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, "'") : intPart
      return fracPart ? `${resultInt}.${fracPart}` : resultInt
    },

    /**
     * Formats a number with a threshold.
     * If x is greater than or equal to threshold, format number without decimal point.
     * Otherwise, format number with precision.
     * Example: formatNumberWithThreshold(123.4324, 2) returns "123"
     * Example: formatNumberWithThreshold(3.522, 1) returns "3.5"
     * Example: formatNumberWithThreshold(1234.5678, 2, 1000, 1) returns "1234.6"
     * @param {number} x - The number to format.
     * @param {number} precision - The number of digits after the point (negative numbers round to 10s, 100s, 1000s, ...).
     * @param {number} threshold - The threshold value.
     * @param {number} thresholdPrecision - The number of digits after the point for the threshold (negative numbers round to 10s, 100s, 1000s, ...).
     * @returns {string} Formatted number or dash.
     */
    formatNumberWithThreshold(x, precision, threshold = 10, thresholdPrecision = 0, thousandSeparator = true) {
      if (x >= threshold) {
        return this.formatNumber(x, thresholdPrecision, thousandSeparator)
      }
      return this.formatNumber(x, precision, thousandSeparator)
    },

    /**
     * Formats a string, returning a dash if the input is falsy.
     * @param {string} str - The string to format.
     * @returns {string} Formatted string or dash.
     */
    formatString(str) {
      return str || '-'
    },
  },
})

Vue.directive('focus', (el) => {
  el.focus()
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
