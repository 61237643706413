import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const castAllParams = (route) => {
  let castParams = {}
  for (const paramName in route.params) {
    const paramValue = Number.parseInt(route.params[paramName], 10)
    if (Number.isNaN(paramValue)) {
      castParams[paramName] = null
    } else {
      castParams[paramName] = Vue.observable(paramValue)
    }
  }
  return castParams
}

const routes = [
  {
    path: '/',
    name: 'portfolios',
    component: () => import(/* webpackChunkName: "portfolios" */ '@/pages/PortfoliosPage'),
  },
  {
    path: '/portfolios/:portfolio_id/import',
    name: 'import',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "import" */ '@/pages/ImportPage'),
  },
  {
    path: '/portfolios/:portfolio_id',
    redirect: {
      name: 'portfolioBuildings',
    },
  },
  {
    path: '/portfolios/:portfolio_id/buildings',
    name: 'portfolioBuildings',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-buildings" */ '@/pages/PortfolioBuildingsPage'),
  },
  {
    path: '/portfolios/:portfolio_id/buildings/add',
    name: 'addBuilding',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "add-building" */ '@/pages/AddBuildingPage'),
  },
  {
    path: '/portfolios/:portfolio_id/buildings/:building_id',
    name: 'buildingDetails',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "building-details" */ '@/pages/BuildingDetailsPage'),
  },
  // TODO: add a home reporting page, and then nest "reida" and "report" pages under it
  // NOTE: rename to more broad "reporting" (or similar), especially if other reporting frameworks are added
  {
    path: '/portfolios/:portfolio_id/reporting',
    name: 'reida',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "reida" */ '@/pages/ReidaPage'),
  },
  // TODO: confusing name -> rename "report" (Absenkpfad) to "reduction-path", "target-path", "reduction-report", or similar
  {
    path: '/portfolios/:portfolio_id/report',
    redirect: {
      name: 'report',
    },
  },
  {
    path: '/portfolios/:portfolio_id/reduction-path',
    name: 'report',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "report" */ '@/pages/ReportPage'),
  },
  {
    path: '/portfolios/:portfolio_id/scenarios',
    name: 'scenarios',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "scenarios" */ '@/pages/ScenariosPage'),
  },
  {
    path: '/portfolios/:portfolio_id/scenarios/:scenario_id',
    name: 'scenario',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "scenario" */ '@/pages/EditScenarioPage'),
  },
  {
    path: '/portfolios/:portfolio_id/license',
    name: 'license',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "license" */ '@/pages/LicensePage'),
  },
  /*
   *
   * SETTINGS PAGES
   *
   */
  {
    path: '/portfolios/:portfolio_id/settings',
    name: 'settings',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-settings" */ '@/pages/settings/PortfolioSettingsPage'),
  },
  // TODO: nest these routes under /portfolios/:portfolio_id/settings/...
  // TODO: add redirects for the old routes
  {
    path: '/portfolios/:portfolio_id/general',
    redirect: {
      name: 'settingsGeneral',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/general',
    name: 'settingsGeneral',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-energy-carriers" */ '@/pages/settings/GeneralPage'),
  },
  {
    path: '/portfolios/:portfolio_id/energy-carriers',
    redirect: {
      name: 'settingsEnergyCarriers',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/energy-carriers',
    name: 'settingsEnergyCarriers',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-energy-carriers" */ '@/pages/settings/EnergyCarriersPage'),
  },
  {
    path: '/portfolios/:portfolio_id/investment-costs',
    redirect: {
      name: 'settingsInvestmentCosts',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/investment-costs',
    name: 'settingsInvestmentCosts',
    props: castAllParams,
    component: () =>
      import(/* webpackChunkName: "portfolio-investment-costs" */ '@/pages/settings/InvestmentCostsPage'),
  },
  {
    path: '/portfolios/:portfolio_id/heat-grids',
    redirect: {
      name: 'settingsHeatGrids',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/heat-grids',
    name: 'settingsHeatGrids',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-heat-grids" */ '@/pages/settings/HeatGridsPage'),
  },
  {
    path: '/portfolios/:portfolio_id/heating-types',
    redirect: {
      name: 'settingsHeatingTypes',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/heating-types',
    name: 'settingsHeatingTypes',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-heating-types" */ '@/pages/settings/HeatingTypesPage'),
  },
  {
    path: '/portfolios/:portfolio_id/lifespans',
    redirect: {
      name: 'settingsLifespans',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/lifespans',
    name: 'settingsLifespans',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-lifespans" */ '@/pages/settings/LifespansPage'),
  },
  {
    path: '/portfolios/:portfolio_id/renovation-standards',
    redirect: {
      name: 'settingsRenovationStandards',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/renovation-standards',
    name: 'settingsRenovationStandards',
    props: castAllParams,
    component: () =>
      import(/* webpackChunkName: "portfolio-renovation-standards" */ '@/pages/settings/RenovationStandardsPage'),
  },
  {
    path: '/portfolios/:portfolio_id/targets',
    redirect: {
      name: 'settingsTargets',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/targets',
    name: 'settingsTargets',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-targets" */ '@/pages/settings/TargetsPage'),
  },
  {
    path: '/portfolios/:portfolio_id/tag-groups',
    redirect: {
      name: 'settingsTagGroups',
    },
  },
  {
    path: '/portfolios/:portfolio_id/settings/tag-groups',
    name: 'settingsTagGroups',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-tag-groups" */ '@/pages/settings/TagGroupsPage'),
  },
  /*
   *
   * USER ADMIN PAGES
   *
   */
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: () => import(/* webpackChunkName: "admin-users" */ '@/pages/admin-users/OverviewPage'),
  },
  {
    path: '/admin/users/new',
    name: 'adminAddUser',
    component: () => import(/* webpackChunkName: "admin-add-user" */ '@/pages/admin-users/AddNewPage'),
  },
  {
    path: '/admin/users/:user_id',
    name: 'adminUser',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-user" */ '@/pages/admin-users/DetailsPage'),
  },
  {
    path: '/admin/users/:user_id/edit',
    name: 'adminEditUser',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-edit-user" */ '@/pages/admin-users/EditPage'),
  },
  {
    path: '/admin/users/:user_id/portfolios/add',
    name: 'adminAddPortfolioToUser',
    props: castAllParams,
    component: () =>
      import(/* webpackChunkName: "admin-add-portfolio-to-user" */ '@/pages/admin-users/AddPortfolioToUserPage'),
  },
  /*
   *
   * PORTFOLIO ADMIN PAGES
   *
   */
  {
    path: '/admin/portfolios',
    name: 'adminPortfolios',
    component: () => import(/* webpackChunkName: "admin-portfolios" */ '@/pages/admin-portfolios/OverviewPage'),
  },
  {
    path: '/admin/portfolios/new',
    name: 'adminAddPortfolio',
    component: () => import(/* webpackChunkName: "admin-add-portfolio" */ '@/pages/admin-portfolios/AddNewPage'),
  },
  {
    path: '/admin/portfolios/import',
    name: 'adminImportPortfolio',
    component: () => import(/* webpackChunkName: "admin-add-portfolio" */ '@/pages/admin-portfolios/ImportPage'),
  },
  {
    path: '/admin/portfolios/:portfolio_id',
    name: 'adminPortfolioDetails',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-portfolio" */ '@/pages/admin-portfolios/DetailsPage'),
  },
  {
    path: '/admin/portfolios/:portfolio_id/buildings',
    name: 'adminPortfolioBuildings',
    props: castAllParams,
    component: () =>
      import(/* webpackChunkName: "admin-portfolio-buildings" */ '@/pages/admin-portfolios/ManageBuildingsPage'),
  },
  {
    path: '/admin/portfolios/:portfolio_id/settings',
    name: 'adminPortfolioSettings',
    props: castAllParams,
    component: () =>
      import(/* webpackChunkName: "admin-portfolio-settings" */ '@/pages/admin-portfolios/EditSettingsPage'),
  },
  {
    path: '/admin/portfolios/:portfolio_id/addbuildings',
    name: 'adminPortfolioAddBuildings',
    props: castAllParams,
    component: () =>
      import(/* webpackChunkName: "admin-portfolio-add-buildings" */ '@/pages/admin-portfolios/AddBuildingsPage'),
  },
  {
    path: '/admin/portfolios/:portfolio_id/users',
    name: 'adminPortfolioUsers',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-portfolio-users" */ '@/pages/admin-portfolios/ManageUsersPage'),
  },
  {
    path: '/admin/portfolios/:portfolio_id/users/add',
    name: 'adminAddUserToPortfolio',
    props: castAllParams,
    component: () =>
      import(/* webpackChunkName: "admin-add-user-to-portfolio" */ '@/pages/admin-portfolios/AddUserToPortfolioPage'),
  },
  /*
   *
   * Login
   *
   */
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/LoginPage'),
  },
  /*
   *
   * Testing
   *
   */
  {
    path: '/testing',
    name: 'testing',
    component: () => import(/* webpackChunkName: "testing" */ '@/pages/TestingPage'),
  },
  /*
   *
   * Other
   *
   */
  {
    path: '*',
    redirect: {
      name: 'portfolios',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['login']
  const authRequired = !publicPages.includes(to.name)

  const user = JSON.parse(localStorage.getItem('user'))
  let token = user && user.authentication_token

  if (authRequired && !token) {
    return next({ name: 'login', query: { redirect: to.path } })
  }
  return next()
})

export default router
